@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400&display=swap");
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Montserrat", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  font-size: 1rem !important;
  letter-spacing: 1px;
}

@media only screen and (max-width: 600px) {
  p {
    font-size: 0.85rem !important;
    letter-spacing: 0px;
  }
  
}
.espacio-img-index {
  padding-top: 20vh;
}
@media only screen and (max-width: 600px) {
  .espacio-img-index {
    padding-top: 0vh;
  }
  
}

.titulos-grandes {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 900 !important;
}
.titulos-grandes-directora {
  font-family: "Sorts Mill Goudy", serif !important;
}

.pantalla-grande{
  width:100%;
  /* display: none !important; */
}

.pantalla-pequenia{
  display: none !important;
}

@media only screen and (max-width: 600px) {
  .pantalla-grande{
    /* width:100%; */
    display: none !important;
  }
  .pantalla-pequenia{
    display: flex !important;
    /* width:100%; */
  }
}


.casas-pantalla-grande{
  width:100%;
  /* display: none !important; */
}

.casas-pantalla-pequenia{
  display: none !important;
}

@media only screen and (max-width: 991px) {
  .casas-pantalla-grande{
    /* width:100%; */
    display: none !important;
  }
  .casas-pantalla-pequenia{
    display: flex !important;
    /* width:100%; */
  }
}

@media only screen and (max-width: 600px) {
  .inscripcion-grande{
    /* width:100%; */
    display: none !important;
  }
  .inscripcion-pequenia{
    display: flex !important;
    /* width:100%; */
  }
}

@media only screen and (max-width: 600px) {
  .titulos-grandes {
    font-family: "Montserrat", sans-serif !important;
    text-align:center;
    
  }

  .titulos-grandes-directora {
    font-family: "Sorts Mill Goudy", serif !important;
    text-align:center;
    
  }
}

@media only screen and (max-width: 600px) {
  .teatro-musical-img1 {
    width:100%
  }

  .teatro-musical-img2 {
    width:100%;
    /* padding-left: 15vw; */
  }

  .teatro-musical-img3 {
    width:100%;
    /* padding-left: 2vw; */
  }
}

.navbar-nav li {
  font-weight: 900;
  letter-spacing: 1.5px;
}

/* Carousel */

.carousel-item {
  /* width: 100%; */
  height: 100vh;
  min-height: 350px;
  background: no-repeat center center scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  color: rgba(0, 0, 0, 0.356);
  /* background-color: red; */
}

@media only screen and (max-width: 600px) {
  .carousel-item {
    height: 57vh !important;
    background: no-repeat center center scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    color: rgba(0, 0, 0, 0.356);
  }

  .imagen_responsive{
    height: 50vh !important;
    width:auto
  }
}



.carousel-indicators li {
  width: 18px;
  height: 18px;
  border-radius: 100%;
}
.carousel-indicators {
  /* top: -10vh; */
  padding-top: -20 !important;
}

/* FIN Carousel */

.navbar {
  background-color: hsla(0, 0%, 100%, 0) !important;
}

.navbar2 {
  background-color: hsla(0, 0%, 100%, 0.671) !important;
}

/* FinCarousel */

/* cuando el usuario está sobre un enlace */
a:hover.sobre-enlace { 
  background: rgba(37, 46, 121, 0.205); 
  
  border-radius: 5rem;
}  /* El usuario esta sobre el enlace */
/* Color Seleccionado */
.color-seleccionado{
  color:#ee833b !important
}
/* Color Seleccionado */
/* .mostrar-ocultar{
 
} */

@media only screen and (max-width: 1024px) {
  .mostrar-ocultar {
    display: none !important;
  }  
}

@media only screen and (max-width: 767px) {
  .mostrar-ocultar {
    display: inline !important;
  }  
}

/* img 100 grande */
@media only screen and (min-width: 768px) {
  .imagen-con-tamanio{
    width:45% !important;
  }
 
}


@media only screen and (max-width: 767px) {
  .imagen-con-tamanio{
    width:70% !important;
  }
 
}

/* cuando el usuario está sobre un enlace */
.contenedor {
  position: relative;
  display: inline-block;
  text-align: center;
  width: 100%;
}

.centrado {
  color: #ffffff;
  width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2.5vw;
}

.centradoIndiceprincipal {
  color: #ffffff;
  width: 80%;
  position: absolute;
  top: 35%;
  left: 40;
  right: 14%;
  font-size: 1.5vw;
}

.centradoCasas {
  color: #ffffff;
  width: 80%;
  position: absolute;
  top: 10%;
  left: 10%;
  right: 14%;
  font-size: 1.5vw;
}

.centradoCasasrifa {
  color: #ffffff;
  width: 80%;
  position: absolute;
  top: 10%;
  left: 10%;
  right: 14%;
  font-size: 4rem;
}


  .boton_personalizado{
    text-decoration: none;
    padding: 10px;
    font-weight: 600;
    font-size: 20px;
    color: #ffffff;
    background-color: #1883ba;
    border-radius: 6px;
    border: 2px solid #0016b0;
  }
  .boton_personalizado:hover{
    color: #1883ba;
    background-color: #ffffff;
  }
  
  .boton_person {
    width: 30%;
    position: absolute;
    top: 70%;
    left: 35%;
  
    text-decoration: none;
      padding: 10px;
      font-weight: 600;
      font-size: 2rem;
      color: #e63d00;
      background-color: #ffffff;
      border-radius: 6px;
      border: 2px solid #ffffff;
  }
  @media only screen and (max-width: 767px) {
    .boton_person {
      width: 60%;
      position: absolute;
      top: 75%;
      left: 20%;
      bottom: 5%;
    
      text-decoration: none;
        padding: 8px;
        font-weight: 600;
        font-size: 0.7rem;
        color: #e63d00;
        background-color: #ffffff;
        border-radius: 6px;
        border: 2px solid #ffffff;
    }  
  }

  
  .boton_personales {
    width: 30%;
    position: absolute;
    top: 70%;
    left: 35%;
  
    text-decoration: none;
      padding: 10px;
      font-weight: 600;
      font-size: 2rem;
      color: #207019;
      background-color: #ffffff;
      border-radius: 6px;
      border: 2px solid #ffffff;
  }
  @media only screen and (max-width: 767px) {
    .boton_personales {
      width: 60%;
      position: absolute;
      top: 75%;
      left: 20%;
      bottom: 5%;
    
      text-decoration: none;
        padding: 8px;
        font-weight: 600;
        font-size: 0.7rem;
        color: #207019;
        background-color: #ffffff;
        border-radius: 6px;
        border: 2px solid #ffffff;
    }  
  }

  .nuestras-clases{
    font-size: 1.6rem !important;
  }
  @media only screen and (max-width: 767px) {
    .nuestras-clases{
      font-size: 1.1rem !important;
    }
  }

  .home-convenios{
    padding-left: 15vh;
    padding-right: 15vh;
  }
  @media only screen and (max-width: 767px) {
    .home-convenios{
      padding-left: 0;
      padding-right: 0;
    }
  }







  .card1 {
    height: auto;
    width: auto;
    max-height: 100px;
    max-width: 100px;
    border-radius: 3px;
    margin: 10px;
  }

  .box {
    width: 480px;
    height: 250px;
    border: 2px solid black;
    background-color: #667766;
  }

  .card-img-top1{
    position: absolute;
    top: 150px;
    left: 550px;
  }
  .card-img-top2{
    position: absolute;
    top: 150px;
    left: 660px;
  }
  .card-img-top3{
    position: absolute;
    top: 150px;
    left: 770px;
  }

  
  